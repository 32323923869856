/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const ReactDOM = require('react-dom');

// see: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
// see: https://github.com/gatsbyjs/gatsby/discussions/17914
exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};
