import React from "react";
import { Link } from "gatsby";
import ContextConsumer from "../context/Context";

interface NavigationLinkProps {
  className?: string;
  to: string;
  state: any;
  location: string;
  style?: React.CSSProperties;
}

interface NavigationLinkState {
  scrollTop: number;
}

export default class NavigationLink extends React.Component<NavigationLinkProps, NavigationLinkState> {
  public state = {
    scrollTop: 0,
  };

  public componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", this.updateScrollTop);
    }
  }

  private updateScrollTop = () => {
    if (typeof window !== `undefined`) {
      this.setState({ scrollTop: window.pageYOffset });
    }
  };

  public render() {
    const { className, state, to, style } = this.props;
    return (
      <ContextConsumer>
        {({ set }) => (
          <Link
            onClick={() => {
              set({
                prevScrollTop: this.state.scrollTop,
                prevLocation: this.props.location,
              });
            }}
            className={className}
            to={to}
            state={state}
            style={style}
          >
            {this.props.children}
          </Link>
        )}
      </ContextConsumer>
    );
  }
}
