/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { ContextProviderComponent } from "../context/Context";
import Footer from "./footer";
import Header from "./header";
import "../styles/layout.css";
import { landingAnimation } from "../common/animation";

const Layout = ({ children, prevLocation, prevScrollTop, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <ContextProviderComponent>
      <style>{landingAnimation}</style>
      <Header
        siteTitle={data.site.siteMetadata.title}
        currentLocation={path}
        prevLocation={prevLocation}
        prevScrollTop={prevScrollTop}
      />
      <main>{children}</main>
      <Footer currentLocation={path} />
    </ContextProviderComponent>
  );
};

export default Layout;
