export const easing1 = "cubic-bezier(.5, .5, .5, 1)";
export const easing2 = "cubic-bezier(.4, .0, .4, 1)";
export const easing3 = "cubic-bezier(.6, .0, .5, 1)";
export const firstDelay = 200;
const fragmentDuration = 1600;
const fragmentMoveDelay = firstDelay + 320;
const contentDelay = 200 + fragmentDuration / 5 + 200;

export const fadeInMove = `
  @keyframes fade-in-move {
    0% {
      opacity: 0;
      transform: translate3d(0, 24px, 0);
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fade-in-move {
    animation: fade-in-move 400ms ${easing1} 0s 1 both;
  }
`;

export const landingAnimation = `
  @media screen and (min-width: 769px) {
    @keyframes landing-fragment {
      0% {
        opacity: 0;
      }
      17.9% {
        opacity: 1;
      }
    }
  }
  @media screen and (min-width: 769px) {
    @keyframes landing-fragment {
      0% {
        opacity: 0;
        fill: #4dcce3;
        transform: translate3d(0, calc(50vh - 360px), 0);
        animation-timing-function: ${easing1}j
      }
      17.9% {
        opacity: 1;
        fill: #4dcce3;
      }
      28.6% {
        transform: translate3d(0, calc(50vh - 360px), 0);
        animation-timing-function: ${easing3}j
      }
      46.4% {
        fill: 9e9e9e;
      }
    }
    @keyframes landing-header {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .landing-header {
      animation: landing-header 600ms ease ${contentDelay}ms 1 both;
    }
    @keyframes landing-index {
      0% {
        opacity: 0;
        transform: translate3d(0, 32px, 0);
      }
      100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    }
    .landing-index {
      animation: landing-index 600ms ${easing1} ${contentDelay}ms 1 both;
    }
  }
  .landing-fragment {
    landing-fragment 1120ms ${easing3} ${firstDelay}ms 1 both
  }
  @keyframes landing-fragment-svg {
    0% {
      fill: #4dcce3;
    }
    100% {
      fill: 9e9e9e;
    }
  }
  .landing-fragment-gradation svg {
    animation: landing-fragment-svg 800ms ${easing3} ${fragmentMoveDelay}ms 1 backwards;
  }
`;
