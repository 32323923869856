import React from "react";
import styled from "styled-components/macro";

import NavigationLink from "./navigation-link";
import { navItems } from "./../common/header-helper";
import { SNSIcons } from "./SNSIcons";

import * as style from "../styles/header.module.css";

interface MobileTopNavigationProps {
  currentLocation: string;
}

export default class MobileTopNavigation extends React.Component<MobileTopNavigationProps> {
  public render() {
    const { currentLocation } = this.props;
    return (
      <div className={style.NavigationMobileTop}>
        {navItems.map((nav) => {
          let itemStyle = style.NavigationItemMobileTop;
          if (nav.path === "/") {
            itemStyle = style.NavigationItemTopMobileFocus;
          }
          return (
            <NavigationLink
              className={itemStyle}
              to={nav.path}
              key={nav.path}
              state={{ prevLocation: currentLocation }}
              location={this.props.currentLocation}
            >
              {nav.label}
            </NavigationLink>
          );
        })}
        <StyledSNSIconsDesktop />
      </div>
    );
  }
}

const StyledSNSIconsDesktop = styled(SNSIcons)`
  @media screen and (min-width: 769px) {
    margin-left: 24px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
