import React from "react";
import { staticPath } from "../common/static-path";
import * as style from "../styles/footer.module.css";
import NavigationLink from "./navigation-link";

const Footer = ({ currentLocation }) => (
  <footer className={style.FooterWrapper}>
    <div className={style.FooterContent}>
      <div className={style.LeftBlock}>
        <div className={style.CompanyBlock}>
          <img className={style.CompanyLogo} src={staticPath("shared/wantedly-logo.svg")} />
          <div className={style.Rights}>&copy;Wantedly, Inc.</div>
        </div>
        <div className={style.OutsideLinks}>
          <a className={style.OutsideLink} target="_blank" rel="noopener noreferrer" href="https://wantedlyinc.com/ja">
            Company Info
          </a>
          <a
            className={style.OutsideLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://wantedlyinc.com/ja/products"
          >
            Our Products
          </a>
          <a
            className={style.OutsideLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://wantedlyinc.com/ja/brand_assets"
          >
            Brand Assets
          </a>
          <a
            className={style.OutsideLink}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.wantedly.com/feed/s/wantedly_engineers"
          >
            Engineering
          </a>
        </div>
      </div>
      <NavigationLink to="/" state={{ prevLocation: currentLocation }} location={currentLocation}>
        <img className={style.RightBlock} src={staticPath("shared/logo-2.svg")} />
      </NavigationLink>
    </div>
  </footer>
);

export default Footer;
