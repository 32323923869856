import React from "react";
import { navItems } from "./../common/header-helper";
import styled from "styled-components/macro";

import { SNSIcons } from "./SNSIcons";
import NavigationLink from "./navigation-link";

import * as style from "../styles/header.module.css";

interface NavigationProps {
  currentLocation: string;
}

export default class Navigation extends React.Component<NavigationProps> {
  public render() {
    const { currentLocation } = this.props;
    return (
      <div className={style.Navigation}>
        {navItems.map((nav) => {
          let itemStyle = style.NavigationItem;
          if (
            (new RegExp(`${nav.path}/?`).test(currentLocation) && nav.path !== "/") ||
            nav.path === currentLocation ||
            (nav.path === "/" && /\/articles\/\d+/.test(currentLocation))
          ) {
            itemStyle = style.NavigationItemFocus;
          }
          return (
            <NavigationLink
              className={itemStyle}
              to={nav.path}
              key={nav.path}
              state={{ prevLocation: currentLocation }}
              location={this.props.currentLocation}
            >
              {nav.label}
            </NavigationLink>
          );
        })}
        <StyledSNSIconsDesktop />
      </div>
    );
  }
}
const StyledSNSIconsDesktop = styled(SNSIcons)`
  @media screen and (min-width: 769px) {
    margin-left: 24px;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
