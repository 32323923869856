import React from "react";
import styled from "styled-components/macro";
import { IconButton } from "@wantedly_private/ui-react";
import { colors } from "@wantedly_private/ui-foundation";

type Props = {
  className?: string;
  size?: number;
  iconSize?: "small" | "large";
};

export const SNSIcons: React.FC<Props> = (props) => {
  return (
    <Content className={props.className}>
      <Link href="https://twitter.com/wantedlydesign">
        <StyledIcon
          iconSize={props.iconSize || "small"}
          size={props.size || "xsmall"}
          appearance="clear"
          name="twitter"
        />
      </Link>
      <Link href="https://www.instagram.com/wantedly.design">
        <StyledIcon
          iconSize={props.iconSize || "small"}
          size={props.size || "xsmall"}
          appearance="clear"
          name="instagram"
        />
      </Link>
    </Content>
  );
};

const Content = styled.div``;
const StyledIcon = styled(IconButton)`
  color: ${colors.whiteAlpha500};
  &:hover {
    color: ${colors.whiteAlpha700};
  }
`;
const Link = styled.a``;
