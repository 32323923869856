import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import { firstDelay, easing3 } from "../common/animation";
import ContextConsumer from "../context/Context";
import * as style from "../styles/header.module.css";
const BREAK_POINT = 768;
interface HeaderFragmentProps {
  currentLocation: string;
  innerStyle: { opacity: number };
  firstWindowHeight: number;
}
interface HeaderFragmentState {
  windowHeight: number;
  windowWidth: number;
}

export default class HeaderFragment extends React.Component<HeaderFragmentProps, HeaderFragmentState> {
  public state = {
    windowHeight: 0,
    windowWidth: 0,
  };

  public setWindowSize = () => {
    const windowHeight = typeof window !== `undefined` ? window.innerHeight : 0;
    const windowWidth = typeof window !== `undefined` ? window.innerWidth : 0;
    this.setState({ windowHeight, windowWidth });
  };

  public componentDidMount() {
    this.setWindowSize();
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", this.setWindowSize);
    }
  }

  private getFragmentColor(loc: string) {
    if (/team\/?/.test(loc)) {
      return "#5890ff";
    } else if (/philosophy\/?/.test(loc)) {
      return "#4dcce3";
    }
    return "#9e9e9e";
  }

  private getAnimationStyle() {
    const { currentLocation } = this.props;
    return /\/articles\/\d+/.test(currentLocation)
      ? `
        .fragment-color {
          opacity: 0;
        }
      `
      : `
      .fragment-color {
        fill: ${this.getFragmentColor(currentLocation)};
      }
    `;
  }

  public render() {
    const { currentLocation } = this.props;
    const parallaxOffsetMin = this.state.windowWidth > BREAK_POINT ? -30 : -40;
    const parallaxOffsetMax = this.state.windowWidth > BREAK_POINT ? -this.state.windowHeight * 0.015 + 30 : 40;
    const heightStyle =
      this.state.windowWidth <= BREAK_POINT && this.state.windowWidth > 0
        ? { height: this.props.firstWindowHeight }
        : null;
    return (heightStyle && heightStyle.height > 0) || this.state.windowWidth > BREAK_POINT ? (
      <ContextConsumer>
        {({ data }: any) => {
          const paddingStyle = /\/articles\/\d+/.test(currentLocation)
            ? { paddingTop: `${80 - data.prevScrollTop}px`, transition: "all 0ms" }
            : {};
          const landingFragmentAnimation =
            currentLocation === "/" && data.prevLocation === ""
              ? { animation: `landing-fragment 1120ms ${easing3} ${firstDelay}ms 1 both` }
              : {};
          const landingFragmentGradation =
            currentLocation === "/" && data.prevLocation === "" ? "landing-fragment-gradation" : "";
          const fadeIn = /\/articles\/\d+/.test(currentLocation) ? "" : "fade-in";
          return (
            <>
              <style>{this.getAnimationStyle()}</style>
              <ParallaxProvider>
                <Parallax
                  className={`${style.FragmentWrapper} ${fadeIn}`}
                  translateY={[parallaxOffsetMin, parallaxOffsetMax]}
                  style={{ ...heightStyle, ...paddingStyle }}
                >
                  <div style={{ ...landingFragmentAnimation, height: "100%" }}>
                    <svg className={`${style.TopFragmentD} fragment-color ${landingFragmentGradation}`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152.06 189.44">
                        <title>Asset 2</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M0,189.44V0H45.31c32.26,0,63.49,7.17,81.92,28.16,22,24.83,24.83,54,24.83,64.77,0,28.92-10,51.71-24.57,67.58-18.44,20-41.48,28.93-81.92,28.93Zm46.33-32.77c25.09,0,43.27-6.66,54.53-18.69,12.29-13,16.13-30.21,16.13-42.75a63.47,63.47,0,0,0-16.64-43c-11.52-12.54-29.18-19.45-55-19.45H35.07v123.9Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentE} ${style.ViewportLarge}  ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.89 189.44">
                        <title>Asset 3</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M0,189.44V0H101.89V32.77H35.07V79.1h64v32.77h-64v44.8h66.82v32.77Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentE} ${style.ViewportSmall} ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101.89 189.44" height="146px" width="219px">
                        <title>Asset 3</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M0,189.44V0H101.89V32.77H35.07V79.1h64v32.77h-64v44.8h66.82v32.77Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg className={`${style.TopFragmentS} ${landingFragmentGradation} fragment-color`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123.13 197.12">
                        <title>Asset 4</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M82.69,54v-.26c-1-12.29-7.94-21-21.25-21C50.43,32.77,42,40.7,42,52,42,64.25,50.18,71.42,66.3,76c39.94,11.78,56.83,29.7,56.83,60.67,0,37.12-26.87,60.42-61.43,60.42-33,0-59.65-23.81-61.7-58.11H36.86c1.8,15.61,11,25.34,25.09,25.34,14.34,0,26.11-10.5,26.11-24.06,0-13.83-9.21-22-24.32-27.4C33.79,102.14,6.91,93.69,6.91,54c0-32.26,25.6-54,55.55-54,29.19,0,54.28,22,56.07,54Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentI} ${style.ViewportLarge} ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.07 189.44">
                        <title>Asset 1</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M0,189.44V0H35.07V189.44Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentI} ${style.ViewportSmall} ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.07 189.44" height="146px">
                        <title>Asset 1</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M0,189.44V0H35.07V189.44Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg className={`${style.TopFragmentG} ${landingFragmentGradation} fragment-color`}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.24 197.12">
                        <title>Asset 5</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M202.24,99.84A94.38,94.38,0,0,1,172,169.21a102.67,102.67,0,0,1-70.14,27.91C49.15,197.12,0,156.67,0,98.56,0,44.54,45.82,0,100.86,0c36.35,0,78.34,22.27,91.91,59.39h-42c-11-16.64-31-26.62-50.69-26.62-36.6,0-65,29.95-65,66,0,31.24,24.06,65.54,66.82,65.54A62.54,62.54,0,0,0,158,129.53H78.59V96.77H202.24Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentN} ${style.ViewportLarge} ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.85 189.44" height="200px">
                        <title>Asset 6</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M114.69,189.44,35.58,51.71h-.51V189.44H0V0H39.94l78.33,137.73h.51V0h35.07V189.44Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                    <svg
                      className={`${style.TopFragmentN} ${style.ViewportSmall} ${landingFragmentGradation} fragment-color`}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.85 189.44">
                        <title>Asset 6</title>
                        <g id="Layer_2" data-name="Layer 2">
                          <g id="Layer_1-2" data-name="Layer 1">
                            <path d="M114.69,189.44,35.58,51.71h-.51V189.44H0V0H39.94l78.33,137.73h.51V0h35.07V189.44Z" />
                          </g>
                        </g>
                      </svg>
                    </svg>
                  </div>
                </Parallax>
              </ParallaxProvider>
            </>
          );
        }}
      </ContextConsumer>
    ) : null;
  }
}
