// extracted by mini-css-extract-plugin
export var Body1 = "header-module--Body1--1733d";
export var Body2 = "header-module--Body2--9bd92";
export var ButtonGlobal = "header-module--ButtonGlobal--4f0a1";
export var Caption = "header-module--Caption--e6e34";
export var Catch2 = "header-module--Catch2--df696";
export var Catch3 = "header-module--Catch3--58679";
export var FragmentWrapper = "header-module--FragmentWrapper--63a89";
export var HeadLine1 = "header-module--HeadLine1--ce451";
export var HeadLine2 = "header-module--HeadLine2--7e242";
export var HeaderWrapper = "header-module--HeaderWrapper--ae3b6";
export var HeaderWrapperMobileTop = "header-module--HeaderWrapperMobileTop--7aba1 header-module--HeaderWrapper--ae3b6 header-module--HeaderWrapper--ae3b6";
export var Headline3 = "header-module--Headline3--d8b18";
export var Leading = "header-module--Leading--a6fd9";
export var Logo = "header-module--Logo--fbc1e";
export var LogoImage = "header-module--LogoImage--609a8";
export var LogoImageMobileTop = "header-module--LogoImageMobileTop--1921c";
export var LogoMobileTop = "header-module--LogoMobileTop--8a783";
export var LogoSub = "header-module--LogoSub--efa33";
export var LogoSubMobileTop = "header-module--LogoSubMobileTop--503e5";
export var NavWrapper = "header-module--NavWrapper--243ff";
export var NavWrapperMobileTop = "header-module--NavWrapperMobileTop--df261";
export var Navigation = "header-module--Navigation--c4cb6";
export var NavigationItem = "header-module--NavigationItem--d1177 header-module--NavigationItemBase--5cddf";
export var NavigationItemBase = "header-module--NavigationItemBase--5cddf";
export var NavigationItemFocus = "header-module--NavigationItemFocus--55643 header-module--NavigationItemBase--5cddf";
export var NavigationItemMobileTop = "header-module--NavigationItemMobileTop--6c53b header-module--NavigationItemBase--5cddf header-module--NavigationItemBase--5cddf";
export var NavigationItemTopMobileFocus = "header-module--NavigationItemTopMobileFocus--462dd header-module--NavigationItemBase--5cddf";
export var NavigationMobileTop = "header-module--NavigationMobileTop--12a1f";
export var SubHead = "header-module--SubHead--0b089";
export var TopFragment = "header-module--TopFragment--bf656";
export var TopFragmentD = "header-module--TopFragmentD--ebd76 header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var TopFragmentE = "header-module--TopFragmentE--9a450 header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var TopFragmentG = "header-module--TopFragmentG--0f71f header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var TopFragmentI = "header-module--TopFragmentI--da81e header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var TopFragmentN = "header-module--TopFragmentN--d56b8 header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var TopFragmentS = "header-module--TopFragmentS--6557d header-module--TopFragment--bf656 header-module--TopFragment--bf656";
export var ViewportLarge = "header-module--ViewportLarge--3fb9f";
export var ViewportSmall = "header-module--ViewportSmall--54b04";
export var hiddenFragments = "header-module--hiddenFragments--32af5";