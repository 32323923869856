import React from "react";
import styled, { css } from "styled-components/macro";

import { staticPath } from "../common/static-path";
import ContextConsumer from "../context/Context";
import { easing1, easing2 } from "../common/animation";

import NavigationLink from "./navigation-link";
import Navigation from "./navigation";
import MobileTopNavigation from "./mobile-top-navigation";
import HeaderFragment from "./header-fragment";
import { SNSIcons } from "./SNSIcons";
import * as style from "../styles/header.module.css";

interface HeaderProps {
  currentLocation: string;
  prevLocation: string;
  location: any;
}

interface HeaderState {
  windowWidth: number;
  windowHeight: number;
  didMount: boolean;
  firstWindowHeight: number;
}

export default class Header extends React.Component<HeaderProps, HeaderState> {
  public state = {
    windowHeight: 0,
    windowWidth: 0,
    didMount: false,
    firstWindowHeight: 0,
  };

  public componentDidMount() {
    if (typeof window !== `undefined`) {
      this.setWindowSize();
    }
    this.setState({ didMount: true });
    this.setState({ firstWindowHeight: window.innerHeight });
  }

  public setWindowSize = () => {
    const windowHeight = typeof window !== `undefined` ? window.innerHeight : 0;
    const windowWidth = typeof window !== `undefined` ? window.innerWidth : 0;
    this.setState({ windowHeight, windowWidth });
  };

  public render() {
    const { currentLocation } = this.props;
    const fragmentOpacityStyle = /\/articles\/\d+/.test(currentLocation) ? { opacity: 0 } : { opacity: 1 };
    return (
      <ContextConsumer>
        {({ data }: any) => {
          const landingNavAnimation = !this.state.didMount
            ? "display-none"
            : data.prevLocation === ""
            ? currentLocation === "/"
              ? "landing-header"
              : "fade-in"
            : "";
          let marginStyle = {};
          if (currentLocation === "/" && this.state.windowWidth <= 768) {
            marginStyle = { paddingTop: this.state.windowHeight / 2 - 26 };
          } else if (/\/articles\/\d+/.test(currentLocation) && this.state.windowWidth > 768) {
            marginStyle = { marginTop: 0 };
          }
          let startPos = 0;
          let easing = easing1;
          let duration = 300;
          let delay = 100;
          if (/\/articles\/\d+/.test(currentLocation) && data.prevLocation === "/") {
            startPos = data.prevScrollTop > 180 ? -96 : 84 - data.prevScrollTop;
            easing = easing1;
            duration = 400;
            delay = 0;
          } else if (
            /\/articles\/\d+/.test(data.prevLocation) &&
            (currentLocation === "/" || currentLocation === "/team/" || currentLocation === "/philosophy/")
          ) {
            startPos = data.prevScrollTop > 96 ? -180 : -84 - data.prevScrollTop;
            easing = easing2;
            duration = 500;
            delay = 100;
          } else {
            startPos = data.prevScrollTop > 180 ? -180 : -data.prevScrollTop;
            easing = easing2;
            duration = 500;
            delay = 0;
          }
          const randomNumber = Math.floor(Math.random() * 1000);
          const moveDownNav = data.prevLocation !== "" ? "move-down" : "";
          const moveDownFragment =
            data.prevLocation !== "" &&
            !/\/articles\/\d+/.test(currentLocation) &&
            !/\/articles\/\d+/.test(data.prevLocation) &&
            data.prevScrollTop < 180
              ? "move-down"
              : "";
          const animationStyle =
            data.prevLocation !== "" ? (
              <style>
                {`
                  @keyframes move-down${randomNumber} {
                    0% {
                      transform: translate3d(0, ${startPos}px, 0);
                    }	
                    100% {
                      transform: translate3d(0, 0, 0);
                    }
                  }
                  @media screen and (min-width: 769px) {
                    .move-down {
                      animation: move-down${randomNumber} ${duration}ms ${easing} ${delay}ms 1 both;
                    }	
                  }
                `}
              </style>
            ) : (
              ""
            );
          if (typeof window === `undefined`) {
            return <div style={{ height: "100vh" }}></div>;
          }
          const styleNavWrapper = currentLocation === "/" ? style.NavWrapperMobileTop : style.NavWrapper;
          const styleLogo = currentLocation === "/" ? style.LogoMobileTop : style.Logo;
          const styleLogoImage = currentLocation === "/" ? style.LogoImageMobileTop : style.LogoImage;
          const styleLogoSub = currentLocation === "/" ? style.LogoSubMobileTop : style.LogoSub;
          const navigation =
            currentLocation === "/" ? (
              <MobileTopNavigation currentLocation={currentLocation} />
            ) : (
              <Navigation currentLocation={currentLocation} />
            );
          return (
            <>
              {animationStyle}
              <header className={style.HeaderWrapper}>
                {data.prevScrollTop > 180 && /\/articles\/\d+/.test(currentLocation) ? null : (
                  <div className={moveDownFragment}>
                    <HeaderFragment
                      firstWindowHeight={this.state.firstWindowHeight}
                      currentLocation={currentLocation}
                      innerStyle={fragmentOpacityStyle}
                    />
                  </div>
                )}
                <div className={`${styleNavWrapper} ${landingNavAnimation} ${moveDownNav}`} style={marginStyle}>
                  <HeadLeftBlock isTopPage={currentLocation === "/"}>
                    <NavigationLink
                      className={styleLogo}
                      to="/"
                      state={{ prevLocation: currentLocation }}
                      location={this.props.currentLocation}
                    >
                      <img className={styleLogoImage} src={staticPath("shared/logo-2.svg")} />
                      <img src={staticPath("shared/logo-sub.svg")} className={styleLogoSub} />
                    </NavigationLink>
                    <StyledSNSIconsMobile iconSize="large" isTopPage={currentLocation === "/"} />
                  </HeadLeftBlock>
                  {navigation}
                </div>
              </header>
            </>
          );
        }}
      </ContextConsumer>
    );
  }
}

const StyledSNSIconsMobile = styled(SNSIcons)<{ isTopPage?: boolean }>`
  @media screen and (min-width: 769px) {
    display: none;
  }
`;
const HeadLeftBlock = styled.div<{ isTopPage?: boolean }>`
  ${(p: { isTopPage?: boolean }) =>
    p.isTopPage &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
`;
